import Banner from "@/components/Cliente/Banner/Banner.vue";

export default {
	name: "Pesquisa",
	components: {
		Banner
	},
	data() {
		return {
			strPesquisa: "",
			arBreadcrumbs: [{
					text: "Início",
					to: "/"
				},
				{
					text: "Pesquisa"
				}
			],
			arResultados: [],
			blLoading: true,
			rolagemMenu: 'none',
			offset: 120
		};
	},
	created() {
		this.pesquisar();
		window.addEventListener("scroll", this.esconderMenuLateral);
	},
	watch: {
		$route(to, from) {
			if (to.query.pesquisa != from.query.pesquisa) {
				this.pesquisar();
			}
		}
	},
	computed: {
		listaAssuntos() {
			if (!this.arResultados || this.arResultados.conteudos.length == 0) {
				return []
			}

			let arAssuntos = [];

			this.arResultados.conteudos.forEach(conteudo => {
				if (!arAssuntos.some(assunto => {
						return assunto.id == conteudo.tema.assunto.id
					})) {

					arAssuntos.push({
						id: conteudo.tema.assunto.id,
						nome: conteudo.tema.assunto.nome,
						conteudos: [conteudo]
					});
				} else {
					arAssuntos[arAssuntos.length - 1].conteudos.push(conteudo);
				}
			});

			return arAssuntos;
		}
	},
	methods: {
		pesquisar() {
			this.blLoading = true;

			this.$root.$api
				.post("pesquisar", {
					pesquisa: this.$route.query.pesquisa
				})
				.then(response => {
					this.arResultados = response.retorno;
					this.strPesquisa = this.$route.query.pesquisa;
					this.blLoading = false;
				});
		},
		criarHash(titulo) {
			return "#" + this.$root.$utils.criaSlug(titulo);
		},
		esconderMenuLateral() {
			this.rolagemMenu = window.scrollY > 100 ? "block" : "none";
		},
		titulosMenuLateral(titulo) {
			return this.$root.$utils.criaSlug(titulo);
		},
		removeBr(texto) {
			return texto.replace('<br>','').replace('<Br>','').replace('<BR>','').replace('<bR>','');
		}
	}
};